<template>
	<div class="garden-list-page">
		<div class="search-box">
			<a-row class="search-row">
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">公司名称：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="company_name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">联系人：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">联系电话：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="phone"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col class="btn-box">
					<a-button type="default" @click="resetTiao">重置</a-button>
					<a-button type="primary" @click="searchList">查询</a-button>
				</a-col>
			</a-row>
			
		</div>
		<div class="color-block"></div>
		<div class="list-table-box">
			<a-table :rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list"
				:pagination="{hideOnSinglePage: true, current: page.current, total: page.total}"
				@change="listChange"
				:bordered="true">
				<template slot="header_img" slot-scope="index, item">
					<div class="head-img-box">
						<img :src="$imglink+item.header_img" >
					</div>
				</template>
				<template slot="is_show" slot-scope="index, item">
					<div class="qiy" :class="{act : item.is_show == 1}" @click="toggleShow(item.park_id,item.is_show)">
						{{item.is_show == 1 ? '启用' : '关闭'}}
					</div>
				</template>
				<template slot="is_recommend" slot-scope="index, item">
					<a-switch :style="{'pointer-events': reIng ? 'none' : 'auto'}" :checked="item.is_recommend == 1" @change="recoChange(item.park_id,item.is_recommend)" />
				</template>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="toEdit(item)">查看</a-button>
						<a-popconfirm title="确认删除该企业吗" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
		<a-drawer
		      title="企业信息"
			  class="drawer-box"
		      :width="720"
		      :visible="visible"
		      :body-style="{ paddingBottom: '80px' }"
		      @close="drawerClose"
		    >
		      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" hide-required-mark>
				<a-form-item  label="企业名称">
					<div class="text">{{curItem.company_name}}</div>
				</a-form-item>
				<a-form-item  label="联系人">
					<div class="text">{{curItem.contact_name}}</div>
				</a-form-item>
				<a-form-item  label="联系电话">
					<div class="text">{{curItem.contact_phone}}</div>
				</a-form-item>
				<a-form-item  label="注册区域">
					<div class="text">{{curItem.register_area}}</div>
				</a-form-item>
				<a-form-item  label="注册时间">
					<div class="text">{{curItem.register_time}}</div>
				</a-form-item>
				<a-form-item  label="产品/服务技术领域">
					<div class="text">{{curItem.main_products}}</div>
				</a-form-item>
				<a-form-item  label="自主知识产权数(件)">
					<div class="text">{{curItem.property_rights}}</div>
				</a-form-item>
				<a-form-item  label="职工总数(人)">
					<div class="text">{{curItem.staff_num}}</div>
				</a-form-item>
				<a-form-item  label="近一年销售收入(元)">
					<div class="text">{{curItem.year_income}}</div>
				</a-form-item>
				<a-form-item  label="国家高新技术企业">
					<div class="text">{{curItem.is_high == 1? '是':'否'}}</div>
				</a-form-item>
				<a-form-item  label="已申报项目/资质">
					<div class="text">{{curItem.declared_projects}}</div>
				</a-form-item>
			  </a-form>
		</a-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			let columns = [{
				title: '企业名称',
				key: 'company_name',
				dataIndex: 'company_name'
			},{
				title: '联系人',
				key: 'contact_name',
				dataIndex: 'contact_name'
			},{
				title: '联系电话',
				key: 'contact_phone',
				dataIndex: 'contact_phone'
			},{
				title: '注册区域',
				key: 'register_area',
				dataIndex: 'register_area'
			},{
				title: '注册时间',
				key: 'register_time',
				dataIndex: 'register_time'
			},
			{
				title: '职工人数',
				key: 'staff_num',
				dataIndex: 'staff_num'
			},{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '140px'
			}]
			return {
				curItem:{},
				visible: false,
				dataLoading: false,
				columns,
				list: [],
				company_name:'',
				name: '',
				phone: '',
				page:{
					current: 1,
					total: 0
				},
				showIng: false,
				reIng: false
			}
		},
		computed: {},
		created() {
			this.getCompanyList()
		},
		mounted() {
			
		},
		methods:{
			searchList() {
				let t = this;
				t.page.current = 1
				t.getCompanyList()
			},
			getCompanyList() {
				let t = this
				let data ={
					company_name: t.company_name,
					name: t.name,
					phone: t.phone,
					page: t.page.current
				}
				t.$post('PolicyMatchList',data).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			toEdit(item) {
				this.curItem = item
				this.visible = true
			},
			deleteItem(item) {
				let t = this
				t.$post('PolicyMatchDelete',{policy_match_id: item.policy_match_id}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success('删除成功',1.5)
					    t.searchList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			listChange(e) {
				console.log(e)
				this.page.current = e.current
				this.getCompanyList();
			},
			resetTiao() {
				this.company_name = ''
				this.name = ''
				this.phone = ''
				// this.searchList()
			},
			drawerClose() {
				this.visible = false
				this.curItem = {}
			}
 		}
	}
</script>

<style lang="less">
	.head-img-box {
		width: 100%;
		max-height: 120px;
		img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
	}
	
	
	.drawer-box {
		.text {
		}
	}
</style>
